import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import slugify from 'slugify';
import toast from 'react-hot-toast';
import auth from '../../firebase.init';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { BadgeInfo, Info, MoveRight } from 'lucide-react';
import MultiLevelCategoryDropdown from './MultiLevelCategoryDropdown';

const AddProductNew = () => {
    const [user] = useAuthState(auth);
    const navigate = useNavigate();
    const [productName, setProductName] = useState('');
    const [productNameError, setProductNameError] = useState('');
    const [charCount, setCharCount] = useState(0);
    const [productSlug, setProductSlug] = useState('');
    const [productDescription, setProductDescription] = useState('');
    const [tags, setTags] = useState([]);
    const [featuredImage, setFeaturedImage] = useState('');
    const [productImageOne, setProductImageOne] = useState('');
    const [price, setPrice] = useState('');
    const [priceError, setPriceError] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [categories, setCategories] = useState([]);
    const [accessLink, setAccessLink] = useState('');
    const [guideLine, setGuideLine] = useState('');
    const [livePreview, setLivePreview] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedMainCategory, setSelectedMainCategory] = useState('');
    const [subcategories, setSubcategories] = useState([]);
    const [uniqueMainCategories, setUniqueMainCategories] = useState([]);
    const [isFormValid, setIsFormValid] = useState(false);
    const [hasVideo, setHasVideo] = useState(true);
    const [youtubeLink, setYoutubeLink] = useState('');
    const [youtubeLinkError, setYoutubeLinkError] = useState('');
    const [hasReachedLimit, setHasReachedLimit] = useState(false);
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [products, setProducts] = useState([]);

    const [descriptionError, setDescriptionError] = useState('');
    const [descriptionCharCount, setDescriptionCharCount] = useState(0);

    const [validationErrors, setValidationErrors] = useState({
        productName: false,
        price: false,
        category: false,
        description: false,
        descriptionLength: false,
        featuredImage: false,
        productImage: false,
        accessLink: false,
        terms: false
    });
    const handleDescriptionChange = (value) => {
        // Strip HTML tags to get plain text character count
        const plainText = value.replace(/<[^>]*>/g, '');
        const charCount = plainText.trim().length;
        setDescriptionCharCount(charCount);

        if (charCount < 1000 || charCount > 4000) {
            setDescriptionError(`Description must be between 1000 and 4000 characters (currently: ${charCount} characters)`);
        } else {
            setDescriptionError('');
        }

        setProductDescription(value);
    };

    // Fetch products
    useEffect(() => {
        fetch(`https://server.enjoywiki.com/flip-server/products?sellerEmail=${user?.email}`)
            .then((res) => res.json())
            .then((info) => {
                setProducts(info.reverse());
                setHasReachedLimit(info.length >= 3);
            });
    }, [user?.email]);

    useEffect(() => {
        fetch('https://server.enjoywiki.com/flip-server/categories')
            .then((res) => res.json())
            .then((info) => {
                const uniqueMainCategories = [
                    ...new Set(info.map((category) => category.mainCategory)),
                ];
                setCategories(info.reverse());
                setUniqueMainCategories(uniqueMainCategories);
                if (selectedMainCategory) {
                    const filteredSubcategories = info.filter(
                        (category) => category.mainCategory === selectedMainCategory
                    );
                    setSubcategories(filteredSubcategories);
                }
            })
            .catch((error) => console.error('Error fetching categories:', error));
    }, [selectedMainCategory]);

    // Quill editor configuration
    const quillModules = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['clean'],
        ],
    };
    // Handle product name change and slug generation
    const handleProductNameChange = (event) => {
        const value = event.target.value;
        setProductName(value);
        setCharCount(value.length);

        if (value.length > 45) {
            setProductNameError('Product name cannot exceed 45 characters.');
        } else {
            setProductNameError('');
        }
        const generatedSlug = slugify(value, {
            lower: true,
            remove: /[^a-zA-Z0-9\s-]/g
        }).replace(/\s+/g, '-');
        setProductSlug(generatedSlug);
    };

    // Handle price input
    const handlePriceChange = (event) => {
        const value = event.target.value;
        setPrice(value);
        if (value === '') {
            setPriceError('');
        } else if (value < 1 || value > 500) {
            setPriceError('Price must be between 1 and 500 USD.');
        } else {
            setPriceError('');
        }
    };
    // Handle tag management
    const handleTagInputKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            const newTag = event.target.value.trim();
            if (newTag && !tags.includes(newTag) && tags.length < 5) {
                setTags([...tags, newTag]);
                event.target.value = '';
            }
        }
    };

    const handleTagRemove = (tagToRemove) => {
        setTags(tags.filter(tag => tag !== tagToRemove));
    };
    // Handle image upload
    const handleImageUpload = async (event, setImage) => {
        const file = event.target.files[0];
        if (!file) return;
        const maxSize = 2 * 1024 * 1024; // 2 MB
        if (file.size > maxSize) {
            toast.error('File size exceeds 2 MB. Please upload a smaller image.');
            return;
        }
        const formData = new FormData();
        formData.append('image', file);
        try {
            const response = await fetch('https://server.enjoywiki.com/image-server/upload', {
                method: 'POST',
                body: formData,
            });
            const data = await response.json();
            if (data.file && data.file.url) {
                setImage(data.file.url);
                toast.success('Image uploaded successfully!');
            }
        } catch (error) {
            console.error('Error uploading image:', error);
            toast.error('Failed to upload image.');
        }
    };
    // Handle image removal
    const handleImageRemove = (setImage) => {
        setImage('');
        toast.success('Image removed successfully!');
    };
    // Update form validation whenever relevant fields change
    useEffect(() => {
        const areAllImagesUploaded =
            featuredImage &&
            productImageOne;
        const isValidForm =
            !productNameError &&
            !priceError &&
            !isSubmitting &&
            areAllImagesUploaded &&
            productName &&
            price &&
            selectedCategory &&
            productDescription &&
            termsAccepted;

        setIsFormValid(isValidForm);
    }, [
        featuredImage,
        productImageOne,
        productNameError,
        priceError,
        isSubmitting,
        productName,
        price,
        selectedCategory,
        productDescription,
        termsAccepted
    ]);
    // Add this validation function with the other handlers
    const validateYoutubeLink = (link) => {
        const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+$/;
        if (!link) {
            setYoutubeLinkError('');
            return true;
        }
        if (!youtubeRegex.test(link)) {
            setYoutubeLinkError('Please enter a valid YouTube URL');
            return false;
        }
        setYoutubeLinkError('');
        return true;
    };

    // Generate unique product ID
    const generateUniqueProductId = async () => {
        let uniqueId;
        while (true) {
            uniqueId = Math.floor(100000 + Math.random() * 900000).toString();
            const response = await fetch(`https://server.enjoywiki.com/flip-server/check-product-id?id=${uniqueId}`);
            const data = await response.json();
            if (!data.exists) {
                break;
            }
        }
        return uniqueId;
    };
    // Form submission handler
    const handleSubmit = async (event) => {
        event.preventDefault();
        if (productNameError || priceError || isSubmitting) {
            return;
        }
        setIsSubmitting(true);
        try {
            const uniqueProductId = await generateUniqueProductId();

            const productData = {
                productId: uniqueProductId,
                productStatus: 'Pending',
                sellerEmail: event.target.sellerEmail.value,
                productName,
                slug: productSlug,
                price: parseFloat(price),
                category: selectedCategory.categoryName,
                mainCategory: selectedCategory.mainCategory,
                categorySlug: selectedCategory.slug,
                youtubeLink: hasVideo ? youtubeLink : '',
                productDescription,
                featuredImage,
                productImageOne,
                accessLink,
                guideLine,
                livePreview,
                tags,
                categoryFeature: 'No',
                homePageFeature: 'No',
                createdAt: new Date().toISOString(),
            };
            const response = await fetch('https://server.enjoywiki.com/flip-server/add-product', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(productData),
            });
            const result = await response.json();
            if (response.ok) {
                toast.success('Product added successfully!');
                // Reset form
                setProductName('');
                setProductSlug('');
                setProductDescription('');
                setTags([]);
                setFeaturedImage('');
                setProductImageOne('');
                setPrice('');
                setAccessLink('');
                setGuideLine('');
                setLivePreview('');
                event.target.reset();
                navigate('/seller/products');
            } else {
                throw new Error(result.message || 'Failed to add product');
            }
        } catch (error) {
            console.error('Error adding product:', error);
            toast.error(error.message || 'Failed to add product');
        } finally {
            setIsSubmitting(false);
        }
    };

    // Update form validation to include detailed error tracking
    useEffect(() => {
        const errors = {
            productName: !productName || productNameError,
            price: !price || priceError,
            category: !selectedCategory,
            description: !productDescription,
            descriptionLength: descriptionCharCount < 1000 || descriptionCharCount > 4000,
            featuredImage: !featuredImage,
            productImage: !productImageOne,
            accessLink: !accessLink,
            terms: !termsAccepted
        };

        setValidationErrors(errors);

        const areAllImagesUploaded = featuredImage && productImageOne;
        const isValidForm = !Object.values(errors).some(error => error);

        setIsFormValid(isValidForm);
    }, [
        productName,
        productNameError,
        price,
        priceError,
        selectedCategory,
        productDescription,
        descriptionCharCount,
        featuredImage,
        productImageOne,
        accessLink,
        termsAccepted
    ]);

    const renderValidationErrors = () => {
        if (isFormValid || isSubmitting) return null;

        const errorMessages = [];

        if (validationErrors.productName) {
            errorMessages.push("Product name is required and must be under 45 characters");
        }
        if (validationErrors.price) {
            errorMessages.push("Price must be between 1 and 500 USD");
        }
        if (validationErrors.category) {
            errorMessages.push("Please select a category");
        }
        if (validationErrors.description || validationErrors.descriptionLength) {
            errorMessages.push(`Product description must be between 1000 and 4000 characters (currently: ${descriptionCharCount} characters)`);
        }
        if (validationErrors.featuredImage) {
            errorMessages.push("Featured image is required");
        }
        if (validationErrors.productImage) {
            errorMessages.push("Product image is required");
        }
        if (validationErrors.accessLink) {
            errorMessages.push("Access link is required");
        }
        if (validationErrors.terms) {
            errorMessages.push("You must accept the terms and conditions");
        }

        return (
            <div className="alert alert-danger mt-3">
                <strong>Please fix the following issues:</strong>
                <ul className="mb-0 mt-2">
                    {errorMessages.map((error, index) => (
                        <li key={index}>* {error}</li>
                    ))}
                </ul>
            </div>
        );
    };

    return (
        <main className="dashboard-main min-vh-100">
            <div className="d-flex flex-column gap-4 pb-110">
                <Helmet>
                    <title>Add New Product | FlipBundle - Upload Your Digital Product</title>
                    <meta name="description" content="Easily add a new digital product to your FlipBundle store. Upload your files, set product details, and start selling to a wide audience." />
                </Helmet>
                <div>
                    <h3 className="text-24 fw-bold text-dark-300 mb-2">Add Product</h3>
                    <ul className="d-flex align-items-center gap-2">
                        <li className="text-dark-200 fs-6">Seller Dashboard</li>
                        <li>
                            <svg xmlns="http://www.w3.org/2000/svg" width={5} height={11} viewBox="0 0 5 11" fill="none">
                                <path d="M1 10L4 5.5L1 1" stroke="#5B5B5B" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </li>
                        <li className="text-lime-300 fs-6">Add Product</li>
                    </ul>
                </div>
                <div className="row justify-content-center">
                    {hasReachedLimit && (
                        <div
                            style={{
                                position: 'fixed',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                zIndex: 1000,
                                width: '90%',
                                maxWidth: '500px'
                            }}
                        >
                            <div className="bg-white p-5 rounded shadow-lg text-center">
                                <h4 className="text-danger mb-3">Maximum Product Limit Reached</h4>
                                <p className="text-muted">
                                    You have reached the maximum limit of 3 products.
                                </p>
                            </div>
                        </div>
                    )}
                    <div className="col-xl-8">
                        <div style={{
                            opacity: hasReachedLimit ? '0.5' : '1',
                            pointerEvents: hasReachedLimit ? 'none' : 'auto',
                            filter: hasReachedLimit ? 'blur(3px)' : 'none',
                        }}>
                            <form onSubmit={handleSubmit} className="comment-form container add-product">
                                <input type="hidden" name="productStatus" value="Approved" />
                                <input type="hidden" name="sellerEmail" value={user?.email} />
                                <div className="columns gap20">
                                    {/* Basic Info */}
                                    <div className="gig-info-card mb-4">
                                        <div className="gig-info-header">
                                            <h4 className="text-18 fw-semibold text-dark-300">Basic Info</h4>
                                        </div>
                                        <div className="gig-info-body bg-white">
                                            <div className="row g-4">
                                                {/* Product Name */}
                                                <div className="col-12">
                                                    <div className="form-container">
                                                        <label className="form-label">
                                                            Item Name (Eg: 70+ Premium WordPress Themes) <br />
                                                            <div className='mt-2'>
                                                                <span className="mt-2">Character count: {charCount}/45</span>
                                                                {productNameError && <span className="text-danger ms-2">({productNameError})</span>}
                                                            </div>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control shadow-none"
                                                            placeholder="Item Name"
                                                            value={productName}
                                                            onChange={handleProductNameChange}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                {/* Price */}
                                                <div className="col-12">
                                                    <div className="form-container">
                                                        <label className="form-label">Price (USD) <span className="text-danger ms-1">*</span></label>
                                                        <input
                                                            type="number"
                                                            className="form-control shadow-none"
                                                            placeholder="Price"
                                                            value={price}
                                                            onChange={handlePriceChange}
                                                            onWheel={(e) => e.target.blur()}
                                                            min="1"
                                                            max="500"
                                                            step="1"
                                                            required
                                                        />
                                                        {priceError && <div className="text-danger">{priceError}</div>}
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="form-container">
                                                        <label className="form-label">Category Selection <span className="text-danger ms-1">*</span></label>
                                                        <MultiLevelCategoryDropdown
                                                            categories={categories}
                                                            onCategorySelect={(category) => {
                                                                setSelectedCategory(category);
                                                                setSelectedMainCategory(category.mainCategory);
                                                            }}
                                                        />
                                                        {!selectedCategory && (
                                                            <small className="text-danger">Please select a category</small>
                                                        )}
                                                    </div>
                                                </div>
                                                {/* Description */}
                                                <div className="col-12 mb-4">
                                                    <label htmlFor="productDescription" className="form-label fw-semibold">
                                                        Product Description
                                                        <span className="text-danger ms-1">*</span>
                                                        <small className="text-muted ms-2">
                                                            ({descriptionCharCount}/1000 characters minimum)
                                                        </small>
                                                    </label>
                                                    <div className="quill-container border rounded bg-light p-2">
                                                        <ReactQuill
                                                            id="productDescription"
                                                            required
                                                            value={productDescription}
                                                            onChange={handleDescriptionChange}
                                                            modules={quillModules}
                                                            className={`form-control quill-editor ${descriptionError ? 'border-danger' : ''}`}
                                                            placeholder="Write a detailed product description here (minimum 1000 characters)..."
                                                        />
                                                    </div>
                                                    {descriptionError && (
                                                        <div className="text-danger mt-2 small">
                                                            {descriptionError}
                                                        </div>
                                                    )}
                                                </div>

                                                <div className="col-12">
                                                    <div className="form-container">
                                                        <div className="d-flex align-items-center gap-2 mb-2">
                                                            <input
                                                                type="checkbox"
                                                                id="hasVideo"
                                                                checked={hasVideo}
                                                                onChange={(e) => setHasVideo(e.target.checked)}
                                                                className="form-check-input"
                                                            />
                                                            <label htmlFor="hasVideo" className="form-check-label">
                                                                Add Video
                                                            </label>
                                                        </div>
                                                        {hasVideo && (
                                                            <div className="mt-2">
                                                                <input
                                                                    type="url"
                                                                    className={`form-control ${youtubeLinkError ? 'border-danger' : ''}`}
                                                                    placeholder="Enter YouTube video URL"
                                                                    value={youtubeLink}
                                                                    onChange={(e) => {
                                                                        setYoutubeLink(e.target.value);
                                                                        validateYoutubeLink(e.target.value);
                                                                    }}
                                                                />
                                                                {youtubeLinkError && (
                                                                    <small className="text-danger">{youtubeLinkError}   <br /></small>
                                                                )}

                                                                <small className="text-muted mt-2">
                                                                    Please enter a valid URL (e.g. https://youtu.be/EeD2pK3R-gA or https://www.youtube.com/watch?v=EeD2pK3R-gA)
                                                                </small>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Images Section */}
                                    <div className="gig-info-card mb-4">
                                        <div className="gig-info-header">
                                            <h4 className="text-18 fw-semibold text-dark-300">Upload Images</h4>
                                        </div>
                                        <div className="gig-info-body bg-white">
                                            {/* Featured Image */}
                                            <div className="mb-4">
                                                <label className="form-label">Featured Image (960x540)<span className="text-danger ms-1">*</span></label>
                                                <div className="d-flex align-items-center gap-3">
                                                    <input
                                                        type="file"
                                                        className="form-control"
                                                        onChange={(e) => handleImageUpload(e, setFeaturedImage)}
                                                        accept="image/*"
                                                        required
                                                    />
                                                    {featuredImage && (
                                                        <button
                                                            type="button"
                                                            className="btn btn-danger"
                                                            onClick={() => handleImageRemove(setFeaturedImage)}
                                                        >
                                                            Remove
                                                        </button>
                                                    )}
                                                </div>
                                                {featuredImage && (
                                                    <img src={featuredImage} alt="Featured" className="mt-2" style={{ height: '100px' }} />
                                                )}
                                                {!featuredImage && (
                                                    <small className="text-danger">This image is required</small>
                                                )}
                                            </div>
                                            {/* Additional Images */}
                                            {[
                                                { state: productImageOne, setState: setProductImageOne, label: 'Product Image (Image size: 960x540)' },

                                            ].map((image, index) => (
                                                <div key={index} className="mb-4">
                                                    <label className="form-label">{image.label}<span className="text-danger ms-1">*</span></label>
                                                    <div className="d-flex align-items-center gap-3">
                                                        <input
                                                            type="file"
                                                            className="form-control"
                                                            onChange={(e) => handleImageUpload(e, image.setState)}
                                                            accept="image/*"
                                                            required
                                                        />
                                                        {image.state && (
                                                            <button
                                                                type="button"
                                                                className="btn btn-danger"
                                                                onClick={() => handleImageRemove(image.setState)}
                                                            >
                                                                Remove
                                                            </button>
                                                        )}
                                                    </div>
                                                    {image.state && (
                                                        <img src={image.state} alt={image.label} className="mt-2" style={{ height: '100px' }} />
                                                    )}
                                                    {!image.state && (
                                                        <small className="text-danger">This image is required</small>
                                                    )}
                                                </div>
                                            ))}
                                            <small
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    fontSize: "0.85rem",
                                                    color: "#6c757d",
                                                    marginTop: "10px",
                                                    padding: "5px",
                                                    borderRadius: "5px",
                                                }}
                                            >
                                                <span style={{ color: "red", marginRight: "5px" }}>*</span>
                                                <Info size={15} style={{ marginRight: "5px" }} />
                                                Maximum File Size: 2MB
                                            </small>
                                        </div>
                                    </div>
                                    {/* Access Information */}
                                    <div className="gig-info-card mb-4">
                                        <div className="gig-info-header">
                                            <h4 className="text-18 fw-semibold text-dark-300">Access Information</h4>
                                        </div>
                                        <div className="gig-info-body bg-white">
                                            <div className="row g-4">
                                                <div className="col-12">
                                                    <div className="form-label">
                                                        <label className="form-label">Product Access URL (Item download link or access link)<span className="text-danger ms-1">*</span></label>
                                                        <input
                                                            type="text"
                                                            className="form-control shadow-none"
                                                            placeholder="Access Link"
                                                            value={accessLink}
                                                            onChange={(e) => setAccessLink(e.target.value)}
                                                            required
                                                        />
                                                        <small className="text-muted">
                                                            <BadgeInfo size={16} color='red' />  Please enter a valid URL (e.g. Google Docs URL or Website URL)
                                                        </small>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="form-label">
                                                        <label className="form-label">Demo/Preview URL (Your item's screenshot/video/document)</label>
                                                        <input
                                                            type="url"
                                                            className="form-control shadow-none"
                                                            placeholder="Demo URL"
                                                            value={livePreview}
                                                            onChange={(e) => setLivePreview(e.target.value)}
                                                            pattern="https?://.+"
                                                        />
                                                        <small className="text-muted">
                                                            <BadgeInfo size={16} color='red' /> Please enter a valid URL (e.g. Google Docs URL, Video URL or Website URL)
                                                        </small>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="form-label">
                                                        <label className="form-label">User Guideline (e.g How to access item, How to download item)</label>
                                                        <textarea
                                                            type="text"
                                                            className="form-control shadow-none"
                                                            rows="5"
                                                            placeholder="User Guideline"
                                                            value={guideLine}
                                                            onChange={(e) => setGuideLine(e.target.value)}
                                                        />
                                                        <small className="text-muted">
                                                            <BadgeInfo size={16} color='red' /> User Guidelines: Provide detailed instructions on how to use your item, how to access it, or how to download it.
                                                        </small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* SEO Information */}
                                    <div className="gig-info-card mb-4">
                                        <div className="gig-info-header">
                                            <h4 className="text-18 fw-semibold text-dark-300">Tags Information</h4>
                                        </div>
                                        <div className="gig-info-body bg-white">
                                            {/* Tags */}
                                            <div className="mb-4">
                                                <label className="form-label">
                                                    Tags (Add max 5 tags by pressing Enter. Tags help buyers find your product in search results.)
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control shadow-none"
                                                    placeholder="Enter a tag and press Enter"
                                                    onKeyDown={handleTagInputKeyDown}
                                                />
                                                <div className="tags-list mt-2">
                                                    {tags.map((tag, index) => (
                                                        <span key={index} className="badge bg-primary me-2 mb-2">
                                                            {tag}
                                                            <button
                                                                type="button"
                                                                className="btn-close btn-close-white ms-2"
                                                                onClick={() => handleTagRemove(tag)}
                                                                style={{ fontSize: '0.75rem' }} />
                                                        </span>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="gig-info-card mb-4">

                                            <div className="gig-info-body bg-white">
                                                <div className="form-check mb-3">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        id="termsCheck"
                                                        checked={termsAccepted}
                                                        onChange={(e) => setTermsAccepted(e.target.checked)}
                                                    />
                                                    <label className="form-check-label" htmlFor="termsCheck">
                                                        I have read and agree to the{' '}
                                                        <Link
                                                            to="/terms"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            className="text-primary"
                                                        >
                                                            Terms and Conditions
                                                        </Link>
                                                    </label>
                                                </div>
                                                {!termsAccepted && (
                                                    <div className="text-danger small">
                                                        You must accept the terms and conditions to proceed
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    {/* Submit Button */}
                                    {renderValidationErrors()}
                                    <div className="text-center">
                                        <button
                                            type="submit"
                                            className="btn btn-primary w-btn-secondary-lg"
                                            disabled={!isFormValid || hasReachedLimit || !termsAccepted}
                                        >
                                            {isSubmitting ? 'Publishing...' : 'Publish Now'}
                                            <MoveRight />
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default AddProductNew;