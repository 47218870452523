import './App.css';
import { Routes, Route, useLocation } from "react-router-dom";
import { useEffect, useState } from 'react';
import NavBar from './components/Shared/NavBar';
import Home from './Pages/Home';
import Login from './Pages/Login';
import Register from './Pages/Register';
import Footer from './components/Shared/Footer/Footer';
import RequireAuth from './components/Shared/RequireAuth';
import Product from './components/HomePage/Product';
import SellerProfile from './components/HomePage/SellerProfile';
import AllCategoryProducts from './components/HomePage/AllCategoryProducts';
import Package from './components/HomePage/Package';
import BuyerAuth from './components/Shared/BuyerAuth';
import PaymentPending from './Pages/Buyer/PaymentPending';
import SupportMessage from './Pages/Buyer/SupportMessage';
import { Toaster } from 'react-hot-toast';
import PayNow from './Pages/Buyer/PayNow';
import CancelledPayment from './components/Shared/Payments/CancelledPayment';
import ReceivedPayment from './components/Shared/Payments/ReceivedPayment';
import ResetPassword from './Pages/ResetPassword';
import BuyerReview from './Pages/Buyer/BuyerReview';
import SellerAuth from './Pages/Seller/SellerAuth';
import SellerWithdraw from './Pages/Seller/SellerWithdraw';
import SellerProducts from './Pages/Seller/SellerProducts';
import Contact from './components/HomePage/Contact';
import SellerAddProduct from './Pages/Seller/SellerAddProduct';
import SellNow from './Pages/SellNow';
import About from './Pages/About';
import TermsAndCondition from './Pages/TermsAndCondition';
import ErrorPage from './Pages/ErrorPage';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import RefundPolicy from './Pages/RefundPolicy';
import UpdateAccessItemForOrder from './Pages/Seller/UpdateAccessItemForOrder';
import RegisterAsSeller from './Pages/RegisterAsSeller';
import RegisterAsBuyer from './Pages/RegisterAsBuyer';
import EditProduct from './Pages/Seller/EditProduct';
import Products from './Pages/ProductPage/Products';
import MainDashBoardLayout from './Pages/Seller/SellerDashboard/MainDashBoardLayout';
import MainDashboardContent from './Pages/Seller/SellerDashboard/MainDashboardContent';
import SellerAllOrders from './Pages/Seller/SellerDashboard/DashboardPage/SellerAllOrders';
import SellerUpdateProfile from './Pages/Seller/SellerDashboard/DashboardPage/SellerUpdateProfile';
import UpdateProfileMust from './Pages/UpdateProfileMust';
import BuyerMainDashBoardLayout from './Pages/Buyer/BuyerDashboard/BuyerMainDashBoardLayout';
import BuyerMainDashboardContent from './Pages/Buyer/BuyerDashboard/BuyerMainDashboardContent';
import BuyerUpdateProfile from './Pages/Buyer/BuyerDashboard/BuyerDashboardPage/BuyerUpdateProfile';
import BuyerAllOrders from './Pages/Buyer/BuyerDashboard/BuyerDashboardPage/BuyerAllOrders';
import SearchProductsAll from './components/HomePage/SearchProductsAll';
import BlogList from './Pages/Blog/BlogList';
import AddBlogPost from './Pages/Blog/AddBlogPost';
import BlogPage from './Pages/Blog/BlogPage';
import BlogDetails from './Pages/Blog/BlogDetails';
import EditBlogPost from './Pages/Blog/EditBlogPost';
import AuthorProfileUpdate from './Pages/Blog/AuthorUpdate/AuthorUpdate';
import AdminAuth from './Pages/Seller/AdminAuth';
import AdminMainDashBoardLayout from './Pages/Admin/AdminMainDashBoardLayout';
import AdminMainDashboardContent from './Pages/Admin/AdminMainDashboardContent';
import AdminAllOrders from './Pages/Admin/AdminAllOrders';
import AdminAllProducts from './Pages/Admin/AdminAllProducts';
import AdminWithdraw from './Pages/Admin/AdminWithdraw';
import AdminAllUsers from './Pages/Admin/AdminAllUsers';
import AdminMainCategory from './Pages/Admin/AdminMainCategory';
import AdminBlogCategories from './Pages/Admin/AdminBlogCategories';
import AdminAllAuthors from './Pages/Admin/AdminAllAuthors';
import AdminEditProduct from './Pages/Admin/AdminEditProduct';
import AdminUpdateProfile from './Pages/Admin/AdminUpdateProfile';
import MessageSystem, { AdminSupportDashboard, SupportTicket } from './Pages/Admin/MessageSystem';
import SupportMessages from './Pages/Admin/SupportMessages/SupportMessages';
import SellerSupport from './Pages/Seller/SupportMessage/SellerSupport';
import CreateSupportTicket from './Pages/Seller/SupportMessage/CreateSupportTicket';
import ContactMessage from './Pages/Admin/ContactMessage/ContactMessage';
import BuyerCreateSupportTicket from './Pages/Seller/SupportMessage/BuyerCreateSupportTicket';
import BuyerSupport from './Pages/Seller/SupportMessage/BuyerSupport';
import AdminAllReview from './Pages/Admin/AdminAllReview';

function App() {
  const location = useLocation();

  // Define paths where you don't want the NavBar and Footer
  const hideNavAndFooter = location.pathname.startsWith('/seller') || location.pathname.startsWith('/buyer') || location.pathname.startsWith('/admin');
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [location]);

  return (
    <main>
      <Toaster />
      {!hideNavAndFooter && <NavBar />}
      <Routes>
        <Route path='*' element={<ErrorPage />}></Route>
        <Route path='/' element={<Home />}></Route>
        <Route path='/products' element={<Products />}></Route>
        <Route path='/sell-now' element={<SellNow />}></Route>
        <Route path='/about' element={<About />}></Route>
        <Route path='/terms' element={<TermsAndCondition />}></Route>
        <Route path='/privacy' element={<PrivacyPolicy />}></Route>
        <Route path='/refundpolicy' element={<RefundPolicy />}></Route>
        <Route path='/contact' element={<Contact />}></Route>
        <Route path='/login' element={<Login />}></Route>
        <Route path='/register' element={<Register />}></Route>
        <Route path='/register-seller' element={<RegisterAsSeller />}></Route>
        <Route path='/register-buyer' element={<RegisterAsBuyer />}></Route>
        <Route path='/reset' element={<ResetPassword />}></Route>
        <Route path="/update-profile" element={<UpdateProfileMust />}></Route>

        <Route path="/buy/:id" element={<RequireAuth><Package /></RequireAuth>}></Route>
        <Route path='/:sliug/:productId' element={<Product />}></Route>
        <Route path='/blog' element={<BlogPage />}></Route>
        <Route path='/blog/:slug' element={<BlogDetails />}></Route>



        {/* Nested Routes for Admin Dashboard */}
        <Route path="/admin" element={<RequireAuth><AdminAuth><AdminMainDashBoardLayout /></AdminAuth></RequireAuth>}>
          <Route path="dashboard" element={<AdminMainDashboardContent />} />
          <Route path="orders" element={<AdminAllOrders />} />
          <Route path="update-order/:id" element={<UpdateAccessItemForOrder />} />
          <Route path="all-products" element={<AdminAllProducts />} />
          <Route path="all-users" element={<AdminAllUsers />} />
          <Route path="all-reviews" element={<AdminAllReview />} />
          <Route path="authors" element={<AdminAllAuthors />} />
          <Route path="categories" element={<AdminMainCategory />} />
          <Route path="blog-categories" element={<AdminBlogCategories />} />

          <Route path="edit-product/:id" element={<AdminEditProduct />} />
          <Route path="all-withdrawal" element={<AdminWithdraw />} />
          <Route path="update-profile" element={<AdminUpdateProfile />} />
          <Route path="blog" element={<BlogList />} />
          <Route path="blog/add-post" element={<AddBlogPost />} />
          <Route path="edit-blog/:id" element={<EditBlogPost />} />
          <Route path="author-profile" element={<AuthorProfileUpdate />} />
          <Route path="support-message" element={<SupportTicket />} />
          <Route path="support-messages" element={<SupportMessages />} />
          <Route path="messages" element={<ContactMessage />} />
        </Route>

        {/* Nested Routes for Seller Dashboard */}
        <Route path="/seller" element={<RequireAuth><SellerAuth><MainDashBoardLayout /></SellerAuth></RequireAuth>}>
          <Route path="dashboard" element={<MainDashboardContent />} />
          <Route path="orders" element={<SellerAllOrders />} />
          <Route path="update-order/:id" element={<UpdateAccessItemForOrder />} />
          <Route path="add-product" element={<SellerAddProduct />} />
          <Route path="products" element={<SellerProducts />} />
          <Route path="edit-product/:id" element={<EditProduct />} />
          <Route path="withdraw" element={<SellerWithdraw />} />
          <Route path="update-profile" element={<SellerUpdateProfile />} />
          <Route path="blog" element={<BlogList />} />
          <Route path="blog/add-post" element={<AddBlogPost />} />
          <Route path="edit-blog/:id" element={<EditBlogPost />} />
          <Route path="author-profile" element={<AuthorProfileUpdate />} />
          <Route path="support-messages" element={<SellerSupport />} />
          <Route path="create-support" element={<CreateSupportTicket />} />

        </Route>

        {/* Nested Routes for Buyer Dashboard */}
        <Route path="/buyer" element={<RequireAuth><BuyerAuth><BuyerMainDashBoardLayout /></BuyerAuth></RequireAuth>}>
          <Route path="dashboard" element={<BuyerMainDashboardContent />} />
          <Route path="orders" element={<BuyerAllOrders />} />
          <Route path="pending-payment" element={<PaymentPending />} />
          <Route path="support" element={<BuyerSupport />} />
          <Route path="update-profile" element={<BuyerUpdateProfile />} />

          <Route path="create-support" element={<BuyerCreateSupportTicket />} />
        </Route>

        {/*  */}
        <Route path="/pay-now/:id" element={<RequireAuth><BuyerAuth><PayNow /></BuyerAuth></RequireAuth>}></Route>
        <Route path="/review/:id" element={<RequireAuth><BuyerAuth><BuyerReview /></BuyerAuth></RequireAuth>}></Route>
        <Route path="/cancelled-payment/:id" element={<RequireAuth><CancelledPayment /></RequireAuth>}></Route>
        <Route path="/received-payment/:id/:paymentId" element={<RequireAuth><ReceivedPayment /></RequireAuth>} />



        <Route path='/profile-seller/:id' element={<SellerProfile />}></Route>
        <Route path='/category/:slug' element={<AllCategoryProducts />}></Route>
        <Route path='/search-products/' element={<SearchProductsAll />}></Route>

      </Routes>
      {!hideNavAndFooter && <Footer />}
    </main>
  );
}

export default App;
