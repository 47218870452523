import React, { useState } from 'react';
import { toast } from 'react-hot-toast';
import axios from 'axios';

const AdminFeatureStatus = ({ product }) => {
    // Use the entire product object instead of just productId
    const [status, setStatus] = useState({
        homePageFeature: product.homePageFeature || "No",
        categoryFeature: product.categoryFeature || "No"
    });

    const handleFeatureToggle = async (featureType) => {
        try {
            // Toggle the feature status
            const newStatus = {
                ...status,
                [featureType]: status[featureType] === "Yes" ? "No" : "Yes"
            };

            // Make API call to update status
            const response = await axios.put(`https://server.enjoywiki.com/flip-server/api/products/${product._id}/feature-status`, {
                homePageFeature: newStatus.homePageFeature,
                categoryFeature: newStatus.categoryFeature
            });

            // Update local state
            setStatus(newStatus);

            // Show success toast
            toast.success(`${featureType === 'homePageFeature' ? 'Home' : 'Category'} Feature ${newStatus[featureType]} Successfully`);

        } catch (error) {
            // Show error toast
            toast.error('Failed to update feature status');
            console.error('Error updating feature status:', error);
        }
    };

    return (
        <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center gap-2">
                <div>
                    <span className='text-dark-200'>Home Featured</span>
                </div>
                <div className="form-check gig-status form-switch m-0">
                    <input
                        className="form-check-input shadow-none"
                        type="checkbox"
                        role="switch"
                        id="homeFeatureSwitch"
                        checked={status.homePageFeature === "Yes"}
                        onChange={() => handleFeatureToggle('homePageFeature')}
                    />
                </div>
            </div>
            <div className="flex-shrink-0">
                <div className="d-flex align-items-center gap-2">
                    <div>
                        <span className='text-dark-200'>Category Featured</span>
                    </div>
                    <div className="form-check gig-status form-switch m-0">
                        <input
                            className="form-check-input shadow-none"
                            type="checkbox"
                            role="switch"
                            id="categoryFeatureSwitch"
                            checked={status.categoryFeature === "Yes"}
                            onChange={() => handleFeatureToggle('categoryFeature')}
                    />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminFeatureStatus;