// import React, { useEffect, useState } from 'react';
// import PaginationGlobal from '../../components/Shared/PaginationGlobal';
// import { Link } from 'react-router-dom';
// import LoadingSpinner from '../../components/Shared/Loading';
// import { Helmet } from 'react-helmet';

// const Products = () => {
//     const [products, setProducts] = useState([]);
//     const [filteredProducts, setFilteredProducts] = useState([]);
//     const [searchTerm, setSearchTerm] = useState('');
//     const [selectedCategory, setSelectedCategory] = useState('');
//     const [sortBy, setSortBy] = useState('');
//     const [priceFilter, setPriceFilter] = useState('');
//     const [reviews, setReviews] = useState([]);
//     const [isLoading, setIsLoading] = useState(true);
//     const [sellers, setSellers] = useState({});
//     const [categories, setCategories] = useState([]);

//     // Pagination calculations
//     const [currentPage, setCurrentPage] = useState(1);
//     const itemsPerPage = 12;
//     const totalPages = Math.ceil(filteredProducts.length / itemsPerPage);
//     const indexOfLastItem = currentPage * itemsPerPage;
//     const indexOfFirstItem = indexOfLastItem - itemsPerPage;
//     const currentItems = filteredProducts.slice(indexOfFirstItem, indexOfLastItem);

//     // Handle page change
//     const handlePageChange = (pageNumber) => {
//         setCurrentPage(pageNumber);
//     };

//     // Fisher-Yates shuffle algorithm
//     const shuffleArray = (array) => {
//         let currentIndex = array.length, randomIndex;
//         while (currentIndex !== 0) {
//             randomIndex = Math.floor(Math.random() * currentIndex);
//             currentIndex--;
//             [array[currentIndex], array[randomIndex]] =
//                 [array[randomIndex], array[currentIndex]];
//         }
//         return array;
//     };

//     // Extract unique categories from products
//     const extractCategories = (productsData) => {
//         const categoryMap = new Map();

//         productsData.forEach(product => {
//             const slug = product.categorySlug;
//             if (!categoryMap.has(slug)) {
//                 categoryMap.set(slug, {
//                     slug: slug,
//                     name: slug
//                         .split('-')
//                         .map(word => word.charAt(0).toUpperCase() + word.slice(1))
//                         .join(' ')
//                 });
//             }
//         });

//         return Array.from(categoryMap.values())
//             .sort((a, b) => a.name.localeCompare(b.name));
//     };

//     // Fetch all sellers
//     const fetchSellers = async () => {
//         try {
//             const res = await fetch(`https://server.enjoywiki.com/flip-server/users`);
//             const users = await res.json();
//             const sellerMap = users.reduce((acc, user) => {
//                 if (user.userRole === 'Seller') {
//                     acc[user.UserEmail] = {
//                         userName: user.userName,
//                         profileURL: user.profileURL,
//                         _id: user._id
//                     };
//                 }
//                 return acc;
//             }, {});
//             setSellers(sellerMap);
//         } catch (error) {
//             console.error('Error fetching sellers:', error);
//         }
//     };

//     // Fetch products and sellers on component mount
//     useEffect(() => {
//         const fetchData = async () => {
//             setIsLoading(true);
//             try {
//                 const productsRes = await fetch(`https://server.enjoywiki.com/flip-server/products`);
//                 const productsData = await productsRes.json();
//                 // Filter for only published products using the correct field name
//                 const publishedProducts = productsData.filter(product => product.productStatus === "Published");
//                 // Shuffle the filtered products
//                 const shuffledProducts = shuffleArray([...publishedProducts]);
//                 setProducts(shuffledProducts);
//                 setFilteredProducts(shuffledProducts);

//                 const uniqueCategories = extractCategories(publishedProducts);
//                 setCategories(uniqueCategories);

//                 await fetchSellers();
//             } catch (error) {
//                 console.error('Error fetching data:', error);
//             } finally {
//                 setIsLoading(false);
//             }
//         };

//         fetchData();
//     }, []);

//     const fetchReviews = async (productId) => {
//         try {
//             const res = await fetch(`https://server.enjoywiki.com/flip-server/product-reviews?productId=${productId}`);
//             const info = await res.json();
//             const filteredReviews = info.filter(review => review.productId === productId);
//             setReviews(filteredReviews.reverse());
//         } catch (error) {
//             console.error('Error fetching reviews:', error);
//         }
//     };

//     // Handle search and filters
//     useEffect(() => {
//         let result = [...products];

//         // Search filter
//         if (searchTerm) {
//             result = result.filter(product =>
//                 product.productName.toLowerCase().includes(searchTerm.toLowerCase())
//             );
//         }

//         // Category filter
//         if (selectedCategory) {
//             result = result.filter(product =>
//                 product.categorySlug === selectedCategory
//             );
//         }

//         // Sort options
//         if (sortBy === 'random') {
//             result = shuffleArray([...result]);
//         } else if (sortBy === 'a_to_z') {
//             result.sort((a, b) => a.productName.localeCompare(b.productName));
//         } else if (sortBy === 'z_to_a') {
//             result.sort((a, b) => b.productName.localeCompare(a.productName));
//         }

//         // Price filter
//         if (priceFilter === 'low_to_high') {
//             result.sort((a, b) => parseFloat(a.price) - parseFloat(b.price));
//         } else if (priceFilter === 'high_to_low') {
//             result.sort((a, b) => parseFloat(b.price) - parseFloat(a.price));
//         }

//         setFilteredProducts(result);
//         setCurrentPage(1);
//     }, [products, searchTerm, selectedCategory, sortBy, priceFilter]);

//     // Handle manual shuffle
//     const handleManualShuffle = () => {
//         setFilteredProducts(prev => shuffleArray([...prev]));
//         setCurrentPage(1);
//     };

//     if (isLoading) {
//         return <div><LoadingSpinner /></div>;
//     }

//     return (
//         <>
//             <Helmet>
//                 <title>All Products - Explore Digital Content on FlipBundle</title>
//                 <meta
//                     name="description"
//                     content="Explore a diverse selection of digital products on FlipBundle, crafted by talented creators. Find quality digital assets, tools, templates, and more to fit your needs."
//                 />
//             </Helmet>

//             <section
//                 className="w-breadcrumb-area"
//                 style={{
//                     backgroundImage:
//                         "url(https://server.enjoywiki.com/image-server/uploads/breadcrumb_image_2024_05_17_11_50_01_3653-1732595195179-243691209.webp)"
//                 }}
//             >
//                 <div className="container">
//                     <div className="row">
//                         <div className="col-auto">
//                             <div
//                                 className="position-relative z-2 aos-init aos-animate"
//                                 data-aos="fade-up"
//                                 data-aos-duration={1000}
//                                 data-aos-easing="linear"
//                             >
//                                 <h2 className="section-title-light mb-2">All Products</h2>
//                                 <nav aria-label="breadcrumb">
//                                     <ol className="breadcrumb w-breadcrumb">
//                                         <li className="breadcrumb-item">
//                                             <a href="/">Home</a>
//                                         </li>
//                                         <li className="breadcrumb-item active" aria-current="page">
//                                             All Products
//                                         </li>
//                                     </ol>
//                                 </nav>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </section>

//             <section className="py-110 bg-offWhite">
//                 <div className="container">
//                     <form action="" id="searchFormId">
//                         <div className="row justify-content-between mb-40">
//                             <div className="col-xl-auto col-12">
//                                 <div className="d-flex flex-column flex-wrap flex-md-row gap-3">
//                                     {/* Input */}
//                                     <div className="custom-input">
//                                         <input
//                                             type="text"
//                                             className="form-control shadow-none"
//                                             placeholder="Search.."
//                                             name="search"
//                                             value={searchTerm}
//                                             onChange={(e) => setSearchTerm(e.target.value)}
//                                         />
//                                     </div>

//                                     {/* Category Select */}
//                                     <div>
//                                         <select
//                                             name="category"
//                                             className="border-0 custom-style-select nice-select select-dropdown"
//                                             value={selectedCategory}
//                                             onChange={(e) => setSelectedCategory(e.target.value)}
//                                         >
//                                             <option value="">All Categories</option>
//                                             {categories.map((category) => (
//                                                 <option key={category.slug} value={category.slug}>
//                                                     {category.name}
//                                                 </option>
//                                             ))}
//                                         </select>
//                                     </div>

//                                     {/* Sort By */}
//                                     <div>
//                                         <select
//                                             name="sort_by"
//                                             className="border-0 custom-style-select nice-select select-dropdown"
//                                             value={sortBy}
//                                             onChange={(e) => setSortBy(e.target.value)}
//                                         >
//                                             <option value="">Default</option>
//                                             <option value="random">Random</option>
//                                             <option value="a_to_z">A to Z (ASC)</option>
//                                             <option value="z_to_a">Z to A (DSC)</option>
//                                         </select>
//                                     </div>

//                                     {/* Price Filter */}
//                                     <div>
//                                         <select
//                                             name="price_filter"
//                                             className="border-0 custom-style-select nice-select select-dropdown"
//                                             value={priceFilter}
//                                             onChange={(e) => setPriceFilter(e.target.value)}
//                                         >
//                                             <option value="">Price (Default)</option>
//                                             <option value="low_to_high">Low to High</option>
//                                             <option value="high_to_low">High to Low</option>
//                                         </select>
//                                     </div>

//                                     {/* Manual Shuffle Button */}
//                                     {/* <button
//                                         type="button"
//                                         className="btn btn-primary"
//                                         onClick={handleManualShuffle}
//                                     >
//                                         Shuffle Products
//                                     </button> */}
//                                 </div>
//                             </div>
//                         </div>
//                     </form>

//                     <div className="tab-content" id="nav-tabContent">
//                         <div
//                             className="tab-pane fade show active"
//                             id="nav-grid"
//                             role="tabpanel"
//                             aria-labelledby="nav-grid-tab"
//                             tabIndex={0}
//                         >
//                             <div className="row row-cols-1 row-cols-xl-4 row-cols-lg-3 row-cols-md-2">
//                                 {currentItems.map((product) => {
//                                     const seller = sellers[product.sellerEmail] || {};
//                                     return (
//                                         <article className="col mb-4" key={product._id}>
//                                             <div className="service-card bg-white">
//                                                 <div className="position-relative recently-view-card-thumb">

//                                                     <Link to={`/${product.slug}/${product.productId}`}>

//                                                         <img
//                                                             src={product.featuredImage}
//                                                             className="recently-view-card-img w-100"
//                                                             height={200}
//                                                             alt={product.productName}
//                                                         />

//                                                     </Link>


//                                                 </div>
//                                                 <div className="service-card-content">
//                                                     <div className="d-flex align-items-center justify-content-between">
//                                                         <div>
//                                                             <h3 className="service-card-price fw-bold">${product.price}</h3>
//                                                         </div>
//                                                         <div className="d-flex align-items-center gap-1">
//                                                             <svg
//                                                                 xmlns="http://www.w3.org/2000/svg"
//                                                                 width={16}
//                                                                 height={15}
//                                                                 viewBox="0 0 16 15"
//                                                                 fill="none"
//                                                             >
//                                                                 <path
//                                                                     d="M16 5.95909C15.8855 6.07153 15.7709 6.21207 15.6564 6.32451C14.4537 7.36454 13.2511 8.37646 12.0484 9.38838C11.9339 9.47271 11.9053 9.55704 11.9625 9.69758C12.3348 11.2717 12.707 12.8739 13.0793 14.448C13.1365 14.6448 13.1079 14.8134 12.9361 14.9258C12.7643 15.0383 12.5925 15.0102 12.4207 14.9258C10.989 14.0826 9.58587 13.2393 8.15415 12.396C8.03961 12.3117 7.9537 12.3117 7.83917 12.396C6.43607 13.2393 5.00435 14.0826 3.60126 14.8977C3.48672 14.9821 3.34355 15.0102 3.20038 14.9821C2.9713 14.9258 2.85676 14.701 2.91403 14.448C3.14311 13.5204 3.34355 12.5928 3.57262 11.6652C3.74443 10.9906 3.8876 10.316 4.05941 9.64136C4.08805 9.52893 4.05941 9.47271 3.97351 9.38838C2.74222 8.34835 1.53957 7.30832 0.308291 6.26829C0.251022 6.21207 0.193753 6.18396 0.165118 6.12775C0.0219457 6.01531 -0.0353233 5.87477 0.0219457 5.678C0.0792147 5.50935 0.222387 5.42502 0.394194 5.39691C0.651905 5.36881 0.909615 5.3407 1.19596 5.3407C2.36998 5.22826 3.54399 5.14393 4.74664 5.0315C4.97572 5.00339 5.20479 4.97528 5.43387 4.97528C5.54841 4.97528 5.60567 4.919065.63431 4.83474C6.2929 3.31685 6.92286 1.82708 7.58146 0.309198C7.66736 0.140545 7.75326 0.0281089 7.9537 0C8.18278 0.0562179 8.32595 0.140545 8.41186 0.365416C8.75547 1.15247 9.09908 1.96762 9.4427 2.75467C9.75768 3.4574 10.044 4.18823 10.359 4.89095C10.3876 4.97528 10.4449 5.0315 10.5594 5.0315C11.4757 5.11583 12.3921 5.17204 13.337 5.25637C14.0815 5.31259 14.8546 5.39691 15.5991 5.45313C15.7996 5.48124 15.9141 5.59368 16 5.76233C16 5.81855 16 5.90288 16 5.95909Z"
//                                                                     fill="currentColor"
//                                                                 />
//                                                             </svg>
//                                                             <span className="service-card-rating">0.0 (0)</span>
//                                                         </div>
//                                                     </div>
//                                                     <h3 className="service-card-title fw-semibold">
//                                                         <Link to={`/${product.slug}/${product.productId}`}>
//                                                             {product.productName}
//                                                         </Link>
//                                                     </h3>
//                                                     <div className="d-flex align-items-center service-card-author">
//                                                         <div className="custom-reletive">
//                                                             <img
//                                                                 src={seller.profileURL || "https://raw.githubusercontent.com/Shah-Limon/em-list/master/images-dash/profile.jpg"}
//                                                                 className="service-card-author-img"
//                                                                 alt={seller.userName || 'seller'}
//                                                             />
//                                                         </div>
//                                                         <Link to={`/profile-seller/${seller?._id}`} className="service-card-author-name">
//                                                             {seller?.userName}
//                                                         </Link>
//                                                     </div>
//                                                 </div>
//                                             </div>
//                                         </article>
//                                     );
//                                 })}
//                             </div>
//                         </div>
//                     </div>

//                     {/* Pagination */}
//                     {filteredProducts.length > 0 && (
//                         <PaginationGlobal
//                             currentPage={currentPage}
//                             totalPages={totalPages}
//                             onPageChange={handlePageChange}
//                         />
//                     )}
//                 </div>
//             </section>
//         </>
//     );
// };

// export default Products;



import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PaginationGlobal from '../../components/Shared/PaginationGlobal';
import LoadingSpinner from '../../components/Shared/Loading';
import { Helmet } from 'react-helmet';

const Products = () => {
    const [products, setProducts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    // Filter states
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [sortBy, setSortBy] = useState('');
    const [priceFilter, setPriceFilter] = useState('');

    const fetchProducts = async () => {
        setIsLoading(true);
        setError(null);
        try {
            const queryParams = new URLSearchParams({
                page: currentPage,
                limit: 12,
                ...(searchTerm && { search: searchTerm }),
                ...(selectedCategory && { category: selectedCategory }),
                ...(sortBy && { sortBy }),
                ...(priceFilter && { priceFilter })
            });

            const response = await fetch(`https://server.enjoywiki.com/flip-server/api/products?${queryParams}`);
            if (!response.ok) {
                throw new Error('Failed to fetch products');
            }

            const data = await response.json();
            setProducts(data.products);
            setCategories(data.categories);
            setTotalPages(data.pagination.totalPages);
        } catch (error) {
            console.error('Error fetching products:', error);
            setError('Failed to load products. Please try again later.');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchProducts();
    }, [currentPage, searchTerm, selectedCategory, sortBy, priceFilter]);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    // Debounce search input
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (currentPage !== 1) {
                setCurrentPage(1);
            } else {
                fetchProducts();
            }
        }, 300);

        return () => clearTimeout(timeoutId);
    }, [searchTerm]);

    return (
        <>
            <Helmet>
                <title>All Products - Explore Digital Content on FlipBundle</title>
                <meta
                    name="description"
                    content="Explore a diverse selection of digital products on FlipBundle, crafted by talented creators. Find quality digital assets, tools, templates, and more to fit your needs."
                />
            </Helmet>

            <section
                className="w-breadcrumb-area"
                style={{
                    backgroundImage:
                        "url(assets/img/breadcrumb-bg.svg)"
                }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-auto">
                            <div
                                className="position-relative z-2 aos-init aos-animate"
                                data-aos="fade-up"
                                data-aos-duration={1000}
                                data-aos-easing="linear"
                            >
                                <h2 className="section-title-light mb-2">All Products</h2>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb w-breadcrumb">
                                        <li className="breadcrumb-item">
                                            <Link to="/">Home</Link>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            All Products
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="py-110 bg-offWhite">
                <div className="container">
                    <form onSubmit={(e) => e.preventDefault()} className="mb-40">
                        <div className="row justify-content-between">
                            <div className="col-xl-auto col-12">
                                <div className="d-flex flex-column flex-wrap flex-md-row gap-3">
                                    {/* Search Input */}
                                    <div className="custom-input">
                                        <input
                                            type="text"
                                            className="form-control shadow-none"
                                            placeholder="Search products..."
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                            aria-label="Search products"
                                        />
                                    </div>

                                    {/* Category Select */}
                                    <div>
                                        <select
                                            className="border-0 custom-style-select nice-select select-dropdown"
                                            value={selectedCategory}
                                            onChange={(e) => setSelectedCategory(e.target.value)}
                                            aria-label="Select category"
                                        >
                                            <option value="">All Categories</option>
                                            {categories.map((category) => (
                                                <option key={category.slug} value={category.slug}>
                                                    {category.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    {/* Sort By */}
                                    <div>
                                        <select
                                            className="border-0 custom-style-select nice-select select-dropdown"
                                            value={sortBy}
                                            onChange={(e) => setSortBy(e.target.value)}
                                            aria-label="Sort products"
                                        >
                                            <option value="">Default Sorting</option>
                                            <option value="random">Random</option>
                                            <option value="a_to_z">A to Z</option>
                                            <option value="z_to_a">Z to A</option>
                                        </select>
                                    </div>

                                    {/* Price Filter */}
                                    <div>
                                        <select
                                            className="border-0 custom-style-select nice-select select-dropdown"
                                            value={priceFilter}
                                            onChange={(e) => setPriceFilter(e.target.value)}
                                            aria-label="Filter by price"
                                        >
                                            <option value="">Price (Default)</option>
                                            <option value="low_to_high">Low to High</option>
                                            <option value="high_to_low">High to Low</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                    {isLoading ? (
                        <div className="py-5">
                            <LoadingSpinner />
                        </div>
                    ) : error ? (
                        <div className="alert alert-danger" role="alert">
                            {error}
                        </div>
                    ) : products.length === 0 ? (
                        <div className="text-center py-5">
                            <h3>No products found</h3>
                            <p>Try adjusting your search or filters</p>
                        </div>
                    ) : (
                        <div className="tab-content" id="nav-tabContent">
                            <div
                                className="tab-pane fade show active"
                                id="nav-grid"
                                role="tabpanel"
                                aria-labelledby="nav-grid-tab"
                                tabIndex={0}
                            >
                                <div className="row row-cols-1 row-cols-xl-4 row-cols-lg-3 row-cols-md-2">
                                    {products.map((product) => (
                                        <article className="col mb-4" key={product._id}>
                                            <div className="service-card bg-white">
                                                <div className="position-relative recently-view-card-thumb">
                                                    <Link to={`/${product.slug}/${product.productId}`}>
                                                        <img
                                                            src={product.featuredImage}
                                                            className="recently-view-card-img w-100"
                                                            height={200}
                                                            alt={product.productName}
                                                            loading="lazy"
                                                        />
                                                    </Link>
                                                </div>
                                                <div className="service-card-content">
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <div>
                                                            <h3 className="service-card-price fw-bold">
                                                                ${Number(product.price).toFixed(2)}
                                                            </h3>
                                                        </div>
                                                        <div className="d-flex align-items-center gap-1">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width={16} height={15} viewBox="0 0 16 15" fill="none">
                                                                <path d="M16 5.95909C15.8855 6.07153 15.7709 6.21207 15.6564 6.32451C14.4537 7.36454 13.2511 8.37646 12.0484 9.38838C11.9339 9.47271 11.9053 9.55704 11.9625 9.69758C12.3348 11.2717 12.707 12.8739 13.0793 14.448C13.1365 14.6448 13.1079 14.8134 12.9361 14.9258C12.7643 15.0383 12.5925 15.0102 12.4207 14.9258C10.989 14.0826 9.58587 13.2393 8.15415 12.396C8.03961 12.3117 7.9537 12.3117 7.83917 12.396C6.43607 13.2393 5.00435 14.0826 3.60126 14.8977C3.48672 14.9821 3.34355 15.0102 3.20038 14.9821C2.9713 14.9258 2.85676 14.701 2.91403 14.448C3.14311 13.5204 3.34355 12.5928 3.57262 11.6652C3.74443 10.9906 3.8876 10.316 4.05941 9.64136C4.08805 9.52893 4.05941 9.47271 3.97351 9.38838C2.74222 8.34835 1.53957 7.30832 0.308291 6.26829C0.251022 6.21207 0.193753 6.18396 0.165118 6.12775C0.0219457 6.01531 -0.0353233 5.87477 0.0219457 5.678C0.0792147 5.50935 0.222387 5.42502 0.394194 5.39691C0.651905 5.36881 0.909615 5.3407 1.19596 5.3407C2.36998 5.22826 3.54399 5.14393 4.74664 5.0315C4.97572 5.00339 5.20479 4.97528 5.43387 4.97528C5.54841 4.97528 5.60567 4.919065.63431 4.83474C6.2929 3.31685 6.92286 1.82708 7.58146 0.309198C7.66736 0.140545 7.75326 0.0281089 7.9537 0C8.18278 0.0562179 8.32595 0.140545 8.41186 0.365416C8.75547 1.15247 9.09908 1.96762 9.4427 2.75467C9.75768 3.4574 10.044 4.18823 10.359 4.89095C10.3876 4.97528 10.4449 5.0315 10.5594 5.0315C11.4757 5.11583 12.3921 5.17204 13.337 5.25637C14.0815 5.31259 14.8546 5.39691 15.5991 5.45313C15.7996 5.48124 15.9141 5.59368 16 5.76233C16 5.81855 16 5.90288 16 5.95909Z" fill="currentColor" />
                                                            </svg>
                                                            <span className="service-card-rating">
                                                           
                                                                {product.reviews.average} ({product.reviews.count} {product.reviews.count === 1 ? 'Review' : 'Reviews'})
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <h3 className="service-card-title fw-semibold">
                                                        <Link to={`/${product.slug}/${product.productId}`}>
                                                            {product.productName}
                                                        </Link>
                                                    </h3>
                                                    <div className="d-flex align-items-center service-card-author">
                                                        <div className="custom-reletive">
                                                            <img
                                                                src={product.seller.profileURL || "https://raw.githubusercontent.com/Shah-Limon/em-list/master/images-dash/profile.jpg"}
                                                                className="service-card-author-img"
                                                                alt={product.seller.name || 'Seller'}
                                                                loading="lazy"
                                                            />
                                                        </div>
                                                        <Link
                                                            to={`/profile-seller/${product.seller._id}`}
                                                            className="service-card-author-name"
                                                        >
                                                            {product.seller.userName}
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </article>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}

                    {/* Pagination */}
                    {!isLoading && !error && products.length > 0 && (
                        <PaginationGlobal
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={handlePageChange}
                        />
                    )}
                </div>
            </section>
        </>
    );
};

export default Products;