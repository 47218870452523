import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import "react-quill/dist/quill.snow.css";
import auth from "../../firebase.init";
import Pagination from "../../components/Shared/Pagination";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import LoadingAnimations from "../../components/Shared/LoadingAnimations";
import { Edit2, Search, Star, Trash2 } from "lucide-react";
import toast from "react-hot-toast";

const AdminAllReview = () => {
    const [reviews, setReviews] = useState([]);
    const [user] = useAuthState(auth);
    const [activeTab, setActiveTab] = useState('all');
    const [searchTerm, setSearchTerm] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [editingReview, setEditingReview] = useState(null);

    // Fetch reviews
    const fetchReviews = () => {
        fetch(`https://server.enjoywiki.com/flip-server/admin-product-reviews`)
            .then((res) => res.json())
            .then((info) => setReviews(info.reviews))
            .catch((error) => {
                console.error('Error fetching reviews:', error);
                toast.error('Failed to fetch reviews');
            });
    };

    useEffect(() => {
        fetchReviews();
    }, [user?.email]);

    const renderStars = (rating) => {
        return [...Array(5)].map((_, index) => (
            <Star
                key={index}
                size={16}
                className={index < rating ? "text-warning" : "text-muted"}
                fill={index < rating ? "#ffc107" : "none"}
            />
        ));
    };

    // Handle Edit Click
    const handleEditClick = (review) => {
        setEditingReview(review);
        setShowModal(true);
    };

    // Handle Update Review
    const handleUpdateReview = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`https://server.enjoywiki.com/flip-server/update-review/${editingReview._id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(editingReview),
            });

            if (response.ok) {
                setReviews(reviews.map(r =>
                    r._id === editingReview._id ? editingReview : r
                ));
                setShowModal(false);
                setEditingReview(null);
                toast.success('Review updated successfully');
                fetchReviews(); // Refresh the reviews list
            } else {
                toast.error('Failed to update review');
            }
        } catch (error) {
            console.error('Error updating review:', error);
            toast.error('Error updating review');
        }
    };

    // Handle Delete Review
    const handleDeleteReview = async (reviewId) => {
        if (window.confirm('Are you sure you want to delete this review?')) {
            try {
                const response = await fetch(`https://server.enjoywiki.com/flip-server/delete-review/${reviewId}`, {
                    method: 'DELETE',
                });

                if (response.ok) {
                    setReviews(reviews.filter(review => review._id !== reviewId));
                    toast.success('Review deleted successfully');
                } else {
                    toast.error('Failed to delete review');
                }
            } catch (error) {
                console.error('Error deleting review:', error);
                toast.error('Error deleting review');
            }
        }
    };


    // Filter reviews based on active tab, search term, and date range
    const getFilteredReviews = () => {
        let filtered = reviews;

        // Filter by rating
        switch (activeTab) {
            case 'five':
                filtered = filtered.filter(review => review.rating === 5);
                break;
            case 'four':
                filtered = filtered.filter(review => review.rating === 4);
                break;
            case 'three':
                filtered = filtered.filter(review => review.rating === 3);
                break;
            case 'two':
                filtered = filtered.filter(review => review.rating === 2);
                break;
            case 'one':
                filtered = filtered.filter(review => review.rating === 1);
                break;
            default:
                break;
        }

        // Filter by date range
        if (startDate && endDate) {
            filtered = filtered.filter(review => {
                const reviewDate = new Date(review.date);
                const start = new Date(startDate);
                const end = new Date(endDate);
                end.setHours(23, 59, 59, 999);
                return reviewDate >= start && reviewDate <= end;
            });
        }

        // Filter by search term
        if (searchTerm.trim()) {
            const searchLower = searchTerm.toLowerCase();
            filtered = filtered.filter(review =>
                (review.productName?.toLowerCase().includes(searchLower)) ||
                (review.reviewerName?.toLowerCase().includes(searchLower)) ||
                (review.reviewerEmail?.toLowerCase().includes(searchLower)) ||
                (review.review?.toLowerCase().includes(searchLower))
            );
        }

        return filtered;
    };

    const filteredReviews = getFilteredReviews();

    // Pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentReviews = filteredReviews.slice(indexOfFirstItem, indexOfLastItem);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    // Reset pagination when filters change
    useEffect(() => {
        setCurrentPage(1);
    }, [searchTerm, startDate, endDate, activeTab]);

    return (
        <main className="dashboard-main min-vh-100">
            <div className="d-flex flex-column gap-4 pb-110 p-4">
                <Helmet>
                    <title>All Reviews | FlipBundle - Manage Product Reviews</title>
                    <meta name="description" content="View and manage product reviews on FlipBundle. Track customer feedback and ratings." />
                </Helmet>

                <div className="d-flex justify-content-between align-items-center">
                    <div>
                        <h3 className="text-24 fw-bold text-dark-300 mb-2">All Reviews</h3>
                        <ul className="d-flex align-items-center gap-2">
                            <li className="text-dark-200 fs-6">Admin Dashboard</li>
                            <li>
                                <svg xmlns="http://www.w3.org/2000/svg" width={5} height={11} viewBox="0 0 5 11" fill="none">
                                    <path d="M1 10L4 5.5L1 1" stroke="#5B5B5B" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </li>
                            <li className="text-lime-300 fs-6">All Reviews</li>
                        </ul>
                    </div>
                </div>

                {/* Filter Tabs */}
                <div>
                    <nav>
                        <div className="d-flex flex-wrap gap-3 align-items-center" role="tablist">
                            <button
                                className={`dashboard-tab-btn ${activeTab === 'all' ? 'active' : ''}`}
                                onClick={() => setActiveTab('all')}
                            >
                                All({reviews.length})
                            </button>
                            <button
                                className={`dashboard-tab-btn ${activeTab === 'five' ? 'active' : ''}`}
                                onClick={() => setActiveTab('five')}
                            >
                                5 Star({reviews.filter(review => review.rating === 5).length})
                            </button>
                            <button
                                className={`dashboard-tab-btn ${activeTab === 'four' ? 'active' : ''}`}
                                onClick={() => setActiveTab('four')}
                            >
                                4 Star({reviews.filter(review => review.rating === 4).length})
                            </button>
                            <button
                                className={`dashboard-tab-btn ${activeTab === 'three' ? 'active' : ''}`}
                                onClick={() => setActiveTab('three')}
                            >
                                3 Star({reviews.filter(review => review.rating === 3).length})
                            </button>
                            <button
                                className={`dashboard-tab-btn ${activeTab === 'two' ? 'active' : ''}`}
                                onClick={() => setActiveTab('two')}
                            >
                                2 Star({reviews.filter(review => review.rating === 2).length})
                            </button>
                            <button
                                className={`dashboard-tab-btn ${activeTab === 'one' ? 'active' : ''}`}
                                onClick={() => setActiveTab('one')}
                            >
                                1 Star({reviews.filter(review => review.rating === 1).length})
                            </button>
                        </div>
                    </nav>

                    {/* Date Range Filter */}
                    <div
                        className="row mt-4 p-3"
                        style={{
                            backgroundColor: "#f8f9fa",
                            borderRadius: "8px",
                            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                        }}
                    >
                        {/* Search Area */}
                        <div className="col-12 col-md-6 mb-3">
                            <div className="position-relative">
                                <label htmlFor="searchInput" className="form-label small text-muted">Search</label>
                                <input
                                    type="text"
                                    id="searchInput"
                                    className="form-control ps-5"
                                    placeholder="Search by name, email or product name..."
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    style={{
                                        borderRadius: "6px",
                                        border: "1px solid #ced4da",
                                    }}
                                />
                            </div>
                        </div>

                        {/* Date Options */}
                        <div className="col-12 col-md-6 d-flex gap-3">
                            <div className="position-relative w-100">
                                <label htmlFor="startDate" className="form-label small text-muted">Start Date</label>
                                <input
                                    type="date"
                                    id="startDate"
                                    className="form-control"
                                    value={startDate}
                                    onChange={(e) => setStartDate(e.target.value)}
                                    style={{
                                        borderRadius: "6px",
                                        border: "1px solid #ced4da",
                                    }}
                                />
                            </div>
                            <div className="position-relative w-100">
                                <label htmlFor="endDate" className="form-label small text-muted">End Date</label>
                                <input
                                    type="date"
                                    id="endDate"
                                    className="form-control"
                                    value={endDate}
                                    onChange={(e) => setEndDate(e.target.value)}
                                    style={{
                                        borderRadius: "6px",
                                        border: "1px solid #ced4da",
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                    {/* Content */}
                    <div className="gig-info-card mt-4">
                        <div className="gig-info-body">
                            <div className="overflow-x-auto">
                                <div className="w-100">
                                    <table className="w-100 dashboard-table">
                                        <thead>
                                            <tr>
                                                <th scope="col" className="ps-4">Reviewer Info</th>
                                                <th scope="col">Product ID</th>
                                                <th scope="col">Rating</th>
                                                <th scope="col">Review</th>
                                                <th scope="col">Date</th>

                                                <th scope="col" className="text-center">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {reviews === null ? (
                                                <tr>
                                                    <td colSpan="7" className="text-center p-4">
                                                        <LoadingAnimations
                                                            type="pulse"
                                                            color="#2563eb"
                                                            size="md"
                                                        />
                                                    </td>
                                                </tr>
                                            ) : currentReviews.length === 0 ? (
                                                <tr>
                                                    <td colSpan="7" className="text-center p-4">
                                                        {searchTerm ? "No reviews found matching your search." : "No Reviews Found!"}
                                                    </td>
                                                </tr>
                                            ) : (
                                                currentReviews.map((review) => (
                                                    <tr key={review._id}>
                                                        <td>
                                                            <div className="d-flex gap-3 align-items-center project-name">
                                                                <div className="order-img">
                                                                    {review.reviewerProfileURL && (
                                                                        <img
                                                                            src={review.reviewerProfileURL}
                                                                            alt={review.userName}
                                                                            style={{ width: '50px', height: '50px', objectFit: 'cover', borderRadius: '8px' }}
                                                                        />
                                                                    )}
                                                                </div>
                                                                <div>
                                                                    <div className="fw-bold">{review.reviewerName}</div>
                                                                    <div className="text-muted small">{review.reviewerEmail}</div>
                                                                </div>
                                                            </div>
                                                        </td>

                                                        <td className="text-dark-200">
                                                            <Link to={`/${review.productSlug}/${review.productCustomId}`}>
                                                                {review.productName}
                                                            </Link>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex gap-1">
                                                                {renderStars(review.rating)}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="text-wrap" style={{ maxWidth: '300px' }}>
                                                                {review.review}
                                                            </div>
                                                        </td>
                                                        <td className="text-dark-200">{review.date}</td>

                                                        <td className="text-center">
                                                            <div className="d-flex gap-2 justify-content-center">
                                                                <button
                                                                    onClick={() => handleEditClick(review)}
                                                                    className="btn btn-primary btn-sm"
                                                                >
                                                                    <Edit2 size={15} />
                                                                </button>
                                                                <button
                                                                    onClick={() => handleDeleteReview(review._id)}
                                                                    className="btn btn-danger btn-sm"
                                                                >
                                                                    <Trash2 size={15} />
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            {/* Pagination */}
                            {filteredReviews.length > itemsPerPage && (
                                <div className="pagination-container mt-4 d-flex justify-content-center">
                                    <Pagination
                                        currentPage={currentPage}
                                        totalPages={Math.ceil(filteredReviews.length / itemsPerPage)}
                                        onPageChange={handlePageChange}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                    {showModal && (
                        <div className="modal show d-block" tabIndex="-1">
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">Edit Review</h5>
                                        <button
                                            type="button"
                                            className="btn-close"
                                            onClick={() => {
                                                setShowModal(false);
                                                setEditingReview(null);
                                            }}
                                        ></button>
                                    </div>
                                    <form onSubmit={handleUpdateReview}>
                                        <div className="modal-body">
                                            <div className="mb-3">
                                                <label className="form-label">Reviewer Name</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={editingReview?.reviewerName || ''}
                                                    onChange={(e) => setEditingReview({
                                                        ...editingReview,
                                                        reviewerName: e.target.value
                                                    })}
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label">Reviewer Email</label>
                                                <input
                                                    type="email"
                                                    className="form-control"
                                                    value={editingReview?.reviewerEmail || ''}
                                                    onChange={(e) => setEditingReview({
                                                        ...editingReview,
                                                        reviewerEmail: e.target.value
                                                    })}
                                                    required
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label">Reviewer Country</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={editingReview?.reviewerCountry || ' '}
                                                    onChange={(e) => setEditingReview({
                                                        ...editingReview,
                                                        country: e.target.value
                                                    })}
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label">Reviewer Profile URL</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={editingReview?.reviewerProfileURL || ''}
                                                    onChange={(e) => setEditingReview({
                                                        ...editingReview,
                                                        reviewerProfileURL: e.target.value
                                                    })}
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label">Date</label>
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    value={editingReview?.date ? new Date(editingReview.date).toISOString().split('T')[0] : ''}
                                                    onChange={(e) => setEditingReview({
                                                        ...editingReview,
                                                        date: e.target.value
                                                    })}
                                                    required
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label">Rating</label>
                                                <select
                                                    className="form-select"
                                                    value={editingReview?.rating || 5}
                                                    onChange={(e) => setEditingReview({
                                                        ...editingReview,
                                                        rating: parseInt(e.target.value)
                                                    })}
                                                >
                                                    <option value="5">5 Stars</option>
                                                    <option value="4">4 Stars</option>
                                                    <option value="3">3 Stars</option>
                                                    <option value="2">2 Stars</option>
                                                    <option value="1">1 Star</option>
                                                </select>
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label">Review</label>
                                                <textarea
                                                    className="form-control"
                                                    rows="4"
                                                    value={editingReview?.review || ''}
                                                    onChange={(e) => setEditingReview({
                                                        ...editingReview,
                                                        review: e.target.value
                                                    })}
                                                ></textarea>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button
                                                type="button"
                                                className="btn btn-secondary"
                                                onClick={() => {
                                                    setShowModal(false);
                                                    setEditingReview(null);
                                                }}
                                            >
                                                Cancel
                                            </button>
                                            <button type="submit" className="btn btn-primary">
                                                Save Changes
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    )}
                    {showModal && <div className="modal-backdrop show"></div>}
                </div>
            </div>
        </main>
    );
};

export default AdminAllReview;